.TableElement{
    width: 50%;
    display: inline-block;
    overflow: hidden;
}

.DataGrid{
    width:auto;
}


.DataRow{
    width:100%;
    align-self: center;
}

.DataColumn{
    width:100%;
}

.Container{
    align-content: center;
    margin-top: 3rem;
}


.HeaderText{
    display: inline;
    font-size: xx-large;
}
.HeaderRow{
margin-bottom: 1rem;
}
.HeaderSubRow{
    align-items: baseline;
}

.HeaderSpace{
    height: 3rem;
}