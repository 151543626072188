@import '~antd/dist/antd.less';

.main-content {
}

.before{
  height: 115px;
}

@font-face {
  font-family: 'Kreon';
  src: local('Kreon'), url(./fonts/Kreon.ttf) format('truetype');
}
@font-face {
  font-family: 'SansPro';
  src: local('SansPro'), url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,300italic,400italic,700,700italic') format('truetype');
}

@MbOrange:#ef7500;
@KreonFont: Kreon,serif;
@SansProFont: SansPro;
@primary-color: #ef7500;@link-color: #ef7500;@highlight-color: #ef7500;