.userverify-pageContainer{
    height:80vh;
    align-content: center;
    text-align: center;
    margin:auto;
    display: flex;
    width: 100%;
}

.centerItems
{
    margin:auto;
    display: flex;
    flex-direction: column;
}

.icon
{
    margin-top: 16px;
    font-size: xx-large;
}
@primary-color: #ef7500;@link-color: #ef7500;@highlight-color: #ef7500;