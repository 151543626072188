.main-navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;;
  height: 115px;
  background: #ffffff;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 0 4px 15px -10px rgb(184, 183, 183);
}


.main-navigation__wrapper{
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-navigation__title h1 {
  margin: 0;
  color: #0D589C;
}

.main-navigation__title{
  width: 100%;
}

.main-navigation__menu {
  margin-left: 1.5rem;
  align-content: flex-end;
  display: flex;
  width: 100%;
  z-index: 9999999;
}

.nav-row{
  width: 100%;
}
.main-navigation__items {
  margin-left: 1.5rem;
}

.main-navigation img{
  top:18px;
  height: 80px;
}
/* 
.main-navigation__title ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
} */
.main-navigation__items ul{
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}



.main-navigation__items a,
.main-navigation__items button, 
.main-navigation__items NavLink {
  text-decoration: none;
  color: #585f65;
  padding: 0.25rem 0.5rem;
  border: none;
  font: inherit;
  background: transparent;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;
  white-space: nowrap;
}

.main-navigation__items a:hover,
.main-navigation__items a:active,
.main-navigation__items a.active,
.main-navigation__items button:hover,
.main-navigation__items button:active 
.main-navigation__items ul li a:hover{
  color: #ef7500;
  border-radius: 5px;
}