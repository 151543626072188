.start-col 
{
}
.start-col >h1
{
  text-align: center;
  font: weight 500;
}
.start-row{
  width: 100%;
}

.start-divider-row{
  height:300px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.start-divider{
  height:100% !important;
}
.searchContainer{
  display: flex;
  width: 80vw;
  min-height: 500px;
  background-color: #ffffffaa;
  padding: 20px;
  margin: auto;
  margin-top: 32px;
}

.searchContent{
  background-color: #ffffffaa;
  margin: auto;
  height:300px;
  padding-left:32px;
  padding-right:32px;
}

.buttons-search{
  margin: 8px auto;
  display: flex;
  justify-content:left;
}
.buttons-search button{
  margin-right: 8px;
}

.pageContainer-berechtigungen
{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.button{
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
}